<template>
  <dashboard-page />
</template>

<script>
import DashboardPage from '../components/DashboardPage.vue'

  export default {
    name: 'Dashboard',

    components: {
      DashboardPage
    },

    beforeCreate: function (){
        if (!this.$session.exists()) {
            this.$router.push('/login')
        }
    }
  }
</script>
