<template>
  <v-container>
    <v-row class="text-center mt-2">
      <v-col cols="3" class="d-none d-lg-flex">
      </v-col>
      <v-col>
        <v-container>
          <v-row>
            <v-col>
              <p class="pt-6 text-h6">
                DASHBOARD
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
                <v-data-table
                  :headers="headers"
                  :items="applicants"
                  :items-per-page="15"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  @click:row="detail"
                  class="elevation-1"
                >
                  <template v-slot:[`item.created_at`]="{ item }">
                    <span>{{ formatDate(item.created_at) }}</span>
                  </template>
                  <template v-slot:[`item.controls`]="props">
                    <v-btn fab dark small color="red" @click="onButtonClick(props.item)">
                        <v-icon dark>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
                <h1>Application today: {{stats.application_today}}</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
                <v-data-table
                  :headers="headerPostNumber"
                  :items="stats.applications_per_post"
                  :items-per-page="5"
                  @click:row="detail"
                  class="elevation-1"
                >
                </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
                <v-data-table
                  :headers="headerSchoolNumber"
                  :items="stats.applications_per_school"
                  :items-per-page="5"
                  @click:row="detail"
                  class="elevation-1"
                ></v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="3" class="d-none d-lg-flex">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'DashboardPage',
  props: {
  },
  data: () => ({
    applicants: [],
    sortBy: "created_at",
    sortDesc: true,
    headers:[
      { text: "Prénom", value: "firstname" },
      { text: "Nom", value: "lastname" },
      { text: "Poste", value: "post" },
      { text: "Ecole", value: "school" },
      { text: "Date", value: "created_at" },
      { text: "Statut", value: "status" },
      { text: "Delete", value: "controls", sortable: false}
    ],
    stats: {},
    headerPostNumber:[
      { text: "Poste", value: "_id" },
      { text: "Nombre", value: "count" }
    ],
    headerSchoolNumber:[
      { text: "School", value: "_id" },
      { text: "Nombre", value: "count" }
    ],
  }),
  beforeCreate: function (){
    //Get data from api before loading page
    this.$http.get('https://api-recrutement.leolearning.fr/applicants', {
      'headers':{
        'Authorization': 'Bearer ' + this.$session.get('jwt')
      }
    }).then(response => {
      if(response.status === 200 && response.data){
        this.applicants = response.data.results
      }
    }).catch(err => {
      if(err.response.status == 401){
        this.$session.destroy()
        this.$router.push('/login')
      }
      else{
        console.log(err.response)
      }
    })

    this.$http.get('https://api-recrutement.leolearning.fr/stats', {
      'headers':{
        'Authorization': 'Bearer ' + this.$session.get('jwt')
      }
    }).then(response => {
      if(response.status === 200 && response.data){
        this.stats = response.data
      }
    }).catch(err => {
      if(err.response.status == 401){
        this.$session.destroy()
        this.$router.push('/login')
      }
      else{
        console.log(err.response)
      }
    })
  },
  methods: {
    detail(row){
      this.$router.push('/dashboard/' + row._id)
    },
    formatDate(value) {
      let date = new Date(value)
      return `${
          date.getDate().toString().padStart(2, '0')}/${
          (date.getMonth() + 1).toString().padStart(2, '0')}/${
          date.getFullYear().toString().padStart(4, '0')} ${
          date.getHours().toString().padStart(2, '0')}:${
          date.getMinutes().toString().padStart(2, '0')}:${
          date.getSeconds().toString().padStart(2, '0')}`
    },
    onButtonClick(item){
      this.$http.delete('https://api-recrutement.leolearning.fr/applicants?id=' + item._id, {
        'headers':{
          'Authorization': 'Bearer ' + this.$session.get('jwt')
        }
      }).then(response => {
        if(response.status === 204){
          const i = this.applicants.findIndex(elem => elem._id == item._id)
          this.applicants.splice(i, 1)
        }
      }).catch(err => {
        if(err.response.status == 401){
          this.$session.destroy()
          this.$router.push('/login')
        }
        else{
          console.log(err.response)
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>